import { Button, Input, message, Typography } from "antd";
import qs from 'query-string';
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


const { Text } = Typography

const Register = (props) => {
    const [width] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerWidth / 375 * 797);
    const [phone, setPhone] = useState('')
    const { search } = useLocation();
    const [pic, setPic] = useState('')
    const navigator = () => {
        window.location.href = 'https://api.user.sdqc.club/#/download/app'
    }

    useEffect(() => {
        document.title = "水到渠成送水到家";
        Request({
            url: "member/getRegisterBanner",
            method: "GET",
        }).then((res) => {
            setPic(res)
        });
    }, []);


    const register = () => {
        Request({
            url: "member/webRegister",
            method: "POST",
            data: {
                mobile: phone,
                token: qs.parse(search).token
            }
        }).then((res) => {
            if (res.member_id) {
                message.success('领取名额成功')
                navigator()
            } else {
                message.error(res.msg)
            }
        });
    };

    const style = {
        height: height + 'px',
        width: width + 'px',
        backgroundColor: '#fff',
        position: 'relative',
        backgroundImage: `url(${pic})` || 'url(image/register.png)',
        display: 'flex',
        justifyContent: 'center',
        // backgroundAttachment: 'fixed',
        backgroundSize: `${width}px auto`,
    };

    const jisuanHeight = (val) => {
        return val / 797 * height + 'px'
    }

    const jisuanWight = (val) => {
        return val / 375 * width + 'px'
    }

    return (
        <Fragment>
            <div style={style}>
                <div style={{ height: jisuanHeight(230), width: jisuanWight(343), display: 'flex', justifyContent: 'center', backgroundImage: 'url(image/register_input.png)', backgroundSize: 'cover', borderRadius: 15, top: jisuanHeight(513), position: 'relative' }}>
                    <Text style={{ fontSize: 16, width: jisuanWight(287), top: jisuanHeight(20), position: 'absolute', fontWeight: 'Medium' }}>手机号</Text>
                    <Input onChange={(e) => setPhone(e.target.value)} placeholder="请输入你的手机号" style={{ width: jisuanWight(287), height: jisuanHeight(44), fontSize: 16, fontWeight: 600, borderColor: '#6E7EFF', borderRadius: 5, position: 'absolute', top: jisuanHeight(52) }} />
                    <Button style={{ width: jisuanWight(287), height: jisuanHeight(40), fontSize: 20, fontWeight: 700, background: 'linear-gradient(to bottom, #38C2FB 0%, #6387FE 100%)', borderRadius: jisuanHeight(20), position: 'absolute', top: jisuanHeight(113) }} onClick={() => {
                        //校验表单数据
                        if (phone.length !== 11) {
                            message.error('请输入正确的手机号')
                            return
                        }
                        register()
                    }}>
                        <Text style={{ color: '#fff' }}>领取名额并注册</Text>
                    </Button>
                    <Button onClick={() => navigator()} style={{ width: jisuanWight(287), height: jisuanHeight(40), fontSize: 20, fontWeight: 700, borderColor: '#6982FF', borderRadius: jisuanHeight(20), position: 'absolute', top: jisuanHeight(165) }}>
                        <Text style={{ color: '#6982FF' }}>立即下载APP</Text>
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default Register;
